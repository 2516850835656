import React from "react";

class App extends React.Component {
  constructor(props) {
    super(props);
    //Initialization of State
    this.state = {
      buttonName: "Erase",
      checked: "Thin"
    };
  }

  componentDidMount() {
    //When Render of Page is Done
    let mPad = new window.SignaturePad(
      document.getElementById("signature-pad"),
      {
        maxWidth: 2,
        penColor: "black",
        backgroundColor: "rgb(255, 255, 255)"
      }
    );
    //Saving the pad data in State
    this.setState({
      signaturePad: mPad
    });
  }
  //on Undo Button Clicked
  onUndoClick = event => {
    let data = this.state.signaturePad.toData();
    if (data) {
      data.pop();
      this.state.signaturePad.fromData(data);
    }
  };

  //When User wants to erase the items
  onEraseClick = event => {
    this.state.buttonName === "Erase"
      ? this.setState(
          {
            buttonName: "Draw",
            defaultBrushSize: this.state.signaturePad.maxWidth
          },
          () => {
            this.state.signaturePad.maxWidth = 12;
            this.state.signaturePad.penColor = "white";
          }
        )
      : this.setState(
          {
            buttonName: "Erase"
          },
          () => {
            this.state.signaturePad.maxWidth = this.state.defaultBrushSize;
            this.state.signaturePad.penColor = "black";
          }
        );
  };
  onClearClick = event => {
    this.state.signaturePad.clear();
  };
  onRadioButtonHandler = e => {
    this.onClearClick();
    e.target.checked &&
      this.setState(
        {
          checked: e.target.value
        },
        () => {
          this.state.signaturePad.maxWidth =
            this.state.checked === "Thin"
              ? "2"
              : this.state.checked === "Thick"
              ? "4"
              : "6";
        }
      );
  };

  render() {
    return (
      <div>
        <canvas
          id="signature-pad"
          className="signature-pad"
          width={600}
          height={250}
        />
        <br />
        <span id="alert-text">
          {this.state.buttonName === "Draw" &&
            "Erase Mode Enabled !!! Please Click on Draw Button to Resume Drawing"}
        </span>
        <br />
        <span>Brush Thickness</span>
        <br />
        <br />
        <label>
          <input
            type="radio"
            name="brushSize"
            value="Thin"
            checked={this.state.checked === "Thin"}
            onChange={this.onRadioButtonHandler}
            defaultChecked
          />
          Thin
        </label>
        <br />
        <label>
          <input
            type="radio"
            name="brushSize"
            value="Thick"
            checked={this.state.checked === "Thick"}
            onChange={this.onRadioButtonHandler}
          />
          Thick
        </label>

        <br />
        <label>
          <input
            type="radio"
            name="brushSize"
            value="Large"
            checked={this.state.checked === "Large"}
            onChange={this.onRadioButtonHandler}
          />
          Large
        </label>
        <br />
        <div className="btn-div">
          <button id="undo" onClick={this.onUndoClick}>
            Undo
          </button>
          <button id="clear" onClick={this.onClearClick}>
            Clear
          </button>
          <button id="erase" onClick={this.onEraseClick}>
            {this.state.buttonName}
          </button>
        </div>
      </div>
    );
  }
}

export default App;
